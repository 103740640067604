* {
  text-decoration: none;
  color: black;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  display: flex;
  padding: 40px 40px;
  height: 100vh;

}

hr {
  height: 4px;
  width: 10%;
  border-width: 0;
  margin: auto;
  background-color: #0904FF;
}

button {
  appearance: none;
  border: none;
  background: none;
}

.heading-first {
  font-size: 70px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}

.heading-second {
  font-size: 35px;
  text-align: center;
  margin-bottom: 23px;
  font-weight: 500;
}

li {
  list-style-type: none;
}

.active {
  color: #ff1100;
}

.text-lefted {
  text-align: left;
  width: 100%;
  font-size: 18px;
}

.btn {
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  background-color: rgb(255, 0, 0);
  min-width: 200px;
  letter-spacing: 1px;
  border: none;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .15);
  cursor: pointer;
  transition: background-color 0.4s ease;
  border-radius: 2px;
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -ms-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.btn .link {
  width: 100%;
}

.btn:hover {
  background-color: #0904FF;
}

.sub {
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
}

.sub-bold {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

#modal-bg {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

#modal-box {
  position: absolute;
  display: flex;
  width: 40%;
  background-color: white;
  padding: 50px;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.cross {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  text-align: end;
  font-weight: 700;
  font-size: 20px;
  transition: color 0.4s ease;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  -ms-transition: color 0.4s ease;
  -o-transition: color 0.4s ease;
}

#modal-box .cross:hover {
  color: #ff1100;
}

#modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 35px;
  overflow: auto;
}

#modal-videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#modal-files {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#modal-files a {
  text-align: center;
  transition: color 0.4s ease;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  -ms-transition: color 0.4s ease;
  -o-transition: color 0.4s ease;
}

#modal-files a:hover {
  color: #ff1100;
}

#modal-content article {
  width: 400px;
  height: 300px;
  overflow: hidden;
}

#modal-title {
  display: flex;
  flex-direction: column;
}

#modal-title .heading-third {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

header {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
  z-index: 1;
  width: 100%;

  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -ms-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
}

.inverted {
  background-color: rgba(0, 0, 0, 0.788);
  height: 50px;
}

.logo-box {
  align-items: center;
}

.logo {
  width: 300px;
  height: 114px;
}

.header-links {
  display: flex;
  cursor: pointer;
}

.header-link {
  padding: 15px 35px;
  color: white;
  font-weight: 600;
  transition: color 0.4s ease;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  -ms-transition: color 0.4s ease;
  -o-transition: color 0.4s ease;
}

.header-link:hover {
  color: #ff1100;
}

#home-hero {
  position: relative;
  background-image: url('../src/assets/img/Hero_02.webp');
  background-size: cover;
}

#home-hero-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  padding-top: 154px;
  width: 100%;
}

#home-hero-content .heading-second {
  color: white;
  margin-top: 10px;
}

.link {
  padding: 15px;
  color: white;
  transition: color 0.4s ease;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  -ms-transition: color 0.4s ease;
  -o-transition: color 0.4s ease;
}

.link:hover {
  color: #ffffff;
}

#home-hero-title {
  display: flex;
  justify-content: center;
}

.img-in-block {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  -webkit-border-radius: 2px;
}

.heading-third {
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.link-in-text {
  color: #ff1100;
}

#products {
  flex-direction: column;
  gap: 50px;
  height: fit-content;
}

#companies-list {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.company {
  display: flex;
  flex-direction: column;
  border: 3px solid;
  border-radius: 2px;
  gap: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.company-top {
  display: flex;
  height: 100px;
  background-color: white;
  padding: 10px 20px;
}

.company-top .logo-box {
  display: flex;
  width: 100%;
  padding-left: 20px;
}

.company-top img {
  width: 154px;
}

.company-content {
  display: flex;
  flex-direction: column;
}

.company-info-block {
  display: flex;
  justify-content: space-around;
  padding: 30px;
  padding-bottom: 0;
  padding-top: 20px;
}

.company-info-block .image-block {
  width: 40%;
}

.company-info-block .img-in-block {
  object-fit: contain;
}

.company-text {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  margin-right: 20px;
  margin-left: 50px;
  gap: 15px;
}

.company-images {
  display: flex;
  padding: 30px;
  padding-bottom: 0;
  padding-top: 30px;
}

.images-row {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  align-items: center;
}

.images-row .image-block {
  width: 40%;
}

.images-row .img-in-block {
  object-fit: contain;
}

.company-content-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 40px;
}

#contact {
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  gap: 50px;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 80%;
}

.contact-content .image-block {
  height: 70%;
  width: 40%;
}

#contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: 10px;
  padding: 10px;
  line-height: 25px;
  width: 50%;
}

#contact-info a {
  font-size: 18px;
}

footer {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #000;
  color: #fff;
  padding: 10px 60px;
  padding-bottom: 0;
}

footer .logo {
  width: 225px;
  height: 85px;
}

footer .sub {
  font-size: 16px;
  color: white;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 200px;
}

.footer-content-block {
  display: flex;
}

.footer-content-block-text {
  display: flex;
  gap: 57px;
  align-items: center;
}

#copyrights {
  font-size: 14px;
  color: white;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.conditions-hero {
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url('../src/assets/img/Hero_02.webp');
  background-size: cover;
  height: 350px;
}

.conditions-hero .heading-second {
  color: white;
  margin-top: 200px;
  font-weight: 600;
  font-size: 45px;
}

.conditions-text {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 60px;
  width: 100%;
}

.conditions-text .heading-third {
  font-weight: 600;
  margin-top: 35px;
}

.conditions-text .sub-bold {
  margin-top: 15px;
}

.main-notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}

.number-error {
  color: #0904FF;
  font-size: 288px;
  font-weight: 700;
  margin-bottom: 66px
}

.error-message {
  color: #0904FF;
  font-size: 36px;
  margin-bottom: 182px;
}

.getback-link {
  color: #0904FF;
  font-size: 18px;
  text-decoration: revert;
  text-align: center;
}

@media screen and (min-width: 600px) and (max-width:908px) {
  .sub-bold {
    font-size: 18px;
  }

  .logo {
    width: 225px;
    height: 85px;
  }

  .heading-first {
    font-size: 50px;
  }

  #modal-box {
    width: 80%;
  }

  #modal-box a {
    font-size: 18px;
  }

  .company-top .logo-box {
    justify-content: center;
  }

  .company-info-block {
    flex-direction: column;
    align-items: center;
  }

  .company-info-block .image-block {
    width: 40%;
  }

  .company-text {
    width: 100%;
  }

  .contact-content {
    flex-direction: column;
  }

  .contact-content .image-block {
    width: 100%;
  }

  .contact-content .img-in-block {
    object-fit: cover;
  }

  #contact-info {
    width: 100%;
  }

  #contact-info .text-lefted {
    text-align: center;
  }

  footer {
    padding: 10px 32px;
  }

  footer .footer-content {
    gap: 10px;
  }

  footer .footer-content-block-text {
    gap: 30px;
  }

  footer .sub {
    white-space: nowrap;
  }

  .main-notfound {
    padding-top: 100px;
  }

  .number-error {
    font-size: 200px;
    margin-bottom: 40px
  }

  .error-message {
    font-size: 30px;
    margin-bottom: 80px;
    text-align: center;
  }
}


@media screen and (max-width: 599px) {
  header {
    background: none;
  }

  hr {
    width: 50%;
  }

  .inverted {
    height: 60px;
  }

  .inverted .header-mob {
    right: 15px;
    top: 15px;
  }

  .heading-third {
    text-align: center;
    font-size: 20px;
  }

  #modal-box {
    width: 100%;
    height: 100%;
    padding: 32px;
  }

  #modal-content article {
    width: 330px;
    height: 305px;
  }

  #modal-files {
    gap: 30px;
  }

  #modal-title .heading-third {
    font-size: 24px;
  }

  #modal-files a {
    font-size: 20px;
    text-align: left;
  }

  .nav-opened {
    background-color: #000000d1;
  }

  .nav-opened .header-mob-icon {
    width: 23px;
  }

  .header-mob {
    display: flex;
    position: absolute;
    right: 20px;
  }

  .header-mob-icon {
    width: 30px;
  }

  .header-mob-nav {
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    display: flex;
    justify-content: right;
    background-color: #000000d1;
    text-align: right;
  }

  .header-mob-nav-box {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .header-mob-nav-list {
    display: flex;
    padding: 25px 0px;
  }

  .header-link {
    width: 100%;
    font-size: 21px;
    color: white;
  }

  .icon-hidden {
    display: none;
  }

  #home-hero-content {
    gap: 10px;
  }

  .heading-first {
    font-size: 40px;
  }

  .logo {
    width: 225px;
    height: 85px;
  }

  .btn {
    font-size: 18px;
    width: 230px;
    align-self: center;
  }

  #home-hero-content .btn {
    margin-top: 30px;
  }

  .heading-second {
    font-size: 30px;
  }

  .sub {
    font-size: 18px;
    text-align: justify;

  }

  #products {
    padding: 40px 0;
  }

  #companies-list {
    gap: 29px;
  }

  .company {
    border: none;
  }

  .company-top {
    justify-content: center;
  }

  .company-top .logo-box {
    width: auto;
  }

  .company-info-block {
    flex-direction: column;
    padding: 20px;
  }

  .company-info-block .image-block {
    width: 100%;
  }

  .company-text {
    width: 100%;
    margin: 0;
  }

  .images-row {
    gap: 0;
    justify-content: center;
    flex-wrap: wrap;

  }

  .images-row .image-block {
    width: 50%;
  }

  .company-content-buttons .btn {
    min-width: 150px;
  }

  #contact {

    padding: 20px 20px;
    height: 70vh;
  }

  #contact-info {
    width: 100%;
  }

  #contact-info .text-lefted {
    text-align: center;
  }

  .footer {
    padding: 0;
    gap: 20px;
  }

  .footer-content {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  #copyrights {
    text-align: center;
    white-space: nowrap;
  }

  .conditions-hero .heading-second {
    margin-top: 95px;
  }

  .conditions-text {
    padding-top: 30px;
  }

  .conditions-text span {
    font-size: 14px;
  }

  .conditions-text .sub-bold {
    font-size: 16px;
  }

  .main-notfound {
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 200px;
  }

  .number-error {
    font-size: 96px;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .error-message {
    font-size: 18px;
    margin-bottom: 133px;
    text-align: center;
  }

  .getback-link {
    font-size: 14px;

  }
}